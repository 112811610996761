import RMlogo from '../utils/RMlogo.svg'
import MogumoguHardwareScreenshot from '../utils/toadstool-hardware-screenshot.png'
import './Home.css'
import { Link } from 'react-router-dom'
import AppStoreDownloadComponent from './AppStoreDownloadComponent'

export default function Home() {
  return (
    <div className="Home">
      <img src={RMlogo} className="App-logo" alt="Rare Mushroom Logo" />
      <div className="columns">
        <div className="column 2"></div>
        <div className="column is-4">
          <div className="blurb-box">
            <div className="blurb">
              <p>
                Mogumogu is your new mushroom best friend! Join others across
                the mycelium network and strive to have the most loved mushroom.
                Feed them, protect them, play catch with them, and more!
              </p>
              <div className="spacer"></div>
              <div className="desktop-only">
                <AppStoreDownloadComponent />
              </div>
            </div>
            <br />
          </div>
        </div>
        <div className="column is-4 screenshot">
          <div className="spacer"></div>
          <div className="blurb-box">
            <img
              className="blurb iphone"
              src={MogumoguHardwareScreenshot}
              alt="Logo"
            />
          </div>
          <div className="phone-only">
            <AppStoreDownloadComponent />
          </div>
        </div>
        <div className="column is-2"></div>
      </div>
      <footer>
        <br />
        support:{' '}
        <a href="mailto:info@raremushroom.com">info@raremushroom.com</a>
        <br />
        <Link to="/mogumogu/privacypolicy">Mogumogu Privacy Policy</Link>
        <br />
        &copy; 2022 Rare Mushroom
      </footer>
    </div>
  )
}
