import React from 'react'
import StoreBadge from 'react-store-badge'

export default function AppStoreDownloadComponent() {
  return (
    <div>
      <p className="download-text">Available now for download</p>
      <StoreBadge
        name="Mogumogu"
        googlePlayUrl="https://play.google.com/store/apps/details?id=com.RareMushroom.Mogumogu&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        appStoreUrl="https://apps.apple.com/us/app/mogumogu-the-mycelium-network/id1645515475"
      />
    </div>
  )
}
