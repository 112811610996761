import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Home from './components/Home'
import PrivacyPolicy from './components/PrivacyPolicy'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/mogumogu/privacypolicy"
          element={<PrivacyPolicy />}
        />
      </Routes>
    </div>
  )
}

export default App
